<template>
  <iframe id="pdfContainer" :src="`/static/pdf/web/viewer.html?file=${path}`" width="100%" height="95%" frameborder="0" />
</template>

<script>
export default {
  name: "xtwhgly",
  data(){
    return{
      path:"系统维护管理员操作说明书.pdf",
    }
  }
}
</script>

<style scoped>

</style>